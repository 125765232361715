// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import VueQuillEditor from 'vue-quill-editor'
import Vue from 'vue'

Vue.use(VueQuillEditor)