import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
import { provideToast } from 'vue-toastification/composition';

var Blank = function Blank() {
  return import('@/layouts/Blank.vue');
};

var Mobile = function Mobile() {
  return import('@/layouts/Mobile.vue');
};

var Desktop = function Desktop() {
  return import('@/layouts/Desktop.vue');
};

export default {
  name: 'App',
  computed: {
    deviceLayout: function deviceLayout() {
      if (this.$route.meta.layout !== 'blank' && this.$route.meta.layout !== 'creation') {
        return this.$device.mobile ? Mobile : Desktop;
      }

      return Blank;
    }
  },
  watch: {
    $route: function $route(value) {
      var fullPath = value.fullPath;

      if (fullPath.indexOf('/auth') < 0 && fullPath.indexOf('/error/not-admin') < 0) {
        this.$store.dispatch('user/findMe');
      }
    }
  },
  setup: function setup() {
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade'
    });
  }
};