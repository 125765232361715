import LanguageAdapter from '@/adapters/language.adapter'
import CategoryAdapter from '@/adapters/category.adapter'
import i18n from '@/plugins/i18n'
import _ from 'lodash'

export default class SeriesAdapter {
  static convertToDisplayFormat = series => {
    const newSeries = _.cloneDeep(series)
    newSeries.languages = LanguageAdapter.toDisplay(newSeries.languages)
    newSeries.category = newSeries.category ? CategoryAdapter.toDisplay(newSeries.category) : {
      languages: { [i18n.locale]: { title: i18n.t('#Tag No Category') } },
      color: '#000000',
    }
    return newSeries
  }

  static convertToApiFormat = series => {
    const converted = _.cloneDeep(series)
    converted.price = String(converted.price).replaceAll(',', '.')
    return converted
  }
}
