/* eslint-disable no-unused-vars */
import router from '@/router'

const redirect = () => {
  if (router.currentRoute.fullPath.indexOf('/auth') < 0) {
    router.push('/error/not-admin').catch(() => {
    })
  }
}

function verifyIfUserIsAdmin(user) {
  if (!user.isAdmin) {
    if (!user.isContentCreator) {
      redirect()
    }
  }
}

export default verifyIfUserIsAdmin