import _ from 'lodash'

export default class KeyWordsAdapter {
  static convertToDisplayFormat = (keyWords, groups) => {
    const dataKeyWords = _.cloneDeep(keyWords)
    const dataGroups = _.cloneDeep(groups)
    const dataFormat = dataGroups.map(group => ({
      title: group.name,
      subTitle: null,
      keyWords: dataKeyWords.filter(element => !element.subGroupId && element.groupId === group.id),
      subAccordionList: group.subgroups.map(subGroup => ({
        label: subGroup.name,
        tagsList: dataKeyWords.filter(element => element.subGroupId === subGroup.id),
      })),
    }))
    return dataFormat
  }
}
