import httpClient from '@/services/http-client/telos.http-client'

const baseUrl = 'feedback'

export default class FeedbackService {
  static async findBySeriesId(seriesId, page, limit, search) {
    const { data } = await httpClient.get([baseUrl, 'seriesId', seriesId].join('/'), {
      params: {
        page,
        limit,
        search,
      },
    })
    return data
  }

  static async findAll(page, limit, search) {
    const { data } = await httpClient.get(baseUrl, { params: { page, limit, search } })
    return data
  }

  static async findById(id) {
    const { data } = await httpClient.get([baseUrl, id].join('/'))
    return data
  }

  static async softDelete(id) {
    const { data } = await httpClient.delete([baseUrl, id].join('/'))
    return data
  }
}