import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import FeatherIcon from '@/components/FeatherIcon'
import i18n from '@/plugins/i18n'
import store from './store'
import router from './router'
import App from './App'

// 3rd party plugins
import '@/plugins/axios'
import '@/plugins/bootstrap'
import '@/plugins/cleave'
import '@/plugins/clipboard'
import '@/plugins/firebase'
import '@/plugins/perfect-scrollbar'
import '@/plugins/portal-vue'
import '@/plugins/quill'
import '@/plugins/ripple'
import '@/plugins/sweet-alerts'
import '@/plugins/toastification'
import '@/plugins/tour'
import '@/plugins/vee-validate'
import '@/plugins/vue-autosuggest'
import '@/plugins/vue-cookies'
import '@/plugins/vue-draggable'
import '@/plugins/vue-html-secure'
import '@/plugins/vue-select'
import '@/plugins/vue-device-detector'
import '@/plugins/v-mask'
import '@/plugins/v-money'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Main styles
require('@/assets/scss/main.scss')

// Feather Icon
Vue.component(FeatherIcon.name, FeatherIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
