import { extend, localize } from 'vee-validate'
import {
  alpha as ruleAlpha,
  alpha_dash as ruleAlphaDash,
  alpha_num as ruleAlphaNum,
  alpha_spaces as ruleAlphaSpaces,
  between as ruleBetween,
  confirmed as ruleConfirmed,
  digits as ruleDigits,
  email as ruleEmail,
  integer as ruleInteger,
  length as ruleLength,
  min as ruleMin,
  numeric as ruleNumeric,
  regex as ruleRegex,
  required as ruleRequired,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
// eslint-disable-next-line object-curly-newline
import i18n from '@/plugins/i18n'
import _ from 'lodash'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', { ...ruleRequired, message: i18n.t('#FormInputError Required') })

export const email = extend('email', { ...ruleEmail, message: i18n.t('#FormInputError Email') })

export const numeric = extend('numeric', { ...ruleNumeric, message: i18n.t('#FormInputError Numeric') })

export const strongPassword = extend('strongPassword', {
  message: i18n.t('#FormInputError Strong Password'),
  validate: value => typeof value === 'string' && value.match(/(?=.*[A-Z].*)(?=.*[0-9].*)(?=.*[a-z].*).{8,}$/),
})

export const alphaSpaces = extend('alphaSpaces', {
  ...ruleAlphaSpaces,
  message: '',
})

export const min = extend('min', ruleMin)

export const confirmed = extend('confirmed', { ...ruleConfirmed, message: i18n.t('#FormInputError Confirmed') })

export const regex = extend('regex', { ...ruleRegex, message: '' })

export const phone = extend('phone', {
  message: i18n.t('#FormInputError Phone'),
  validate: value => typeof value === 'string' && value.match(/\(+[0-9]+\)+\s[0-9]+-[0-9]{3,}/),
})

export const noRepeated = extend('noRepeated', {
  message: i18n.t('#FormInputError No Repeated'),
  validate: (value, context) => _.cloneDeep(context)
    .map(text => text.trim().toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''))
    .indexOf(_.cloneDeep(value).trim().toLowerCase().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')) === -1,
})

export const noAlpha = extend('noAlpha', {
  message: '',
  validate: value => typeof value === 'string' && value.match(/^[^a-zA-Z]*$/),
})

export const noNumber = extend('noNumber', {
  message: '',
  validate: value => typeof value === 'string' && value.match(/[^0-9.]/),
})

export const noSpecial = extend('noSpecial', {
  message: i18n.t('#FormInputError No Special'),
  validate: value => typeof value === 'string' && value.match(/^[A-Za-z0-9 ]+$/),
})

export const between = extend('between', ruleBetween)

export const alpha = extend('alpha', ruleAlpha)

export const integer = extend('integer', ruleInteger)

export const digits = extend('digits', ruleDigits)

export const alphaDash = extend('alpha-dash', ruleAlphaDash)

export const alphaNum = extend('alpha-num', ruleAlphaNum)

export const length = extend('length', ruleLength)

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
