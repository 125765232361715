import KeyWordsAdapter from '@/adapters/keywords.adapter'
// import testKeyWords from '@/views/miscellaneous/KeyWords'
import KeyWordsService from '@/services/keywords.service'
import GroupsService from '@/services/groups.service'

export default {
  namespaced: true,
  state: {
    allKeyWords: [],
    allGroups: [],
  },
  getters: {
    allKeyWords(state) {
      return state.allKeyWords
    },
    dataKeyWords(state) {
      return KeyWordsAdapter.convertToDisplayFormat(state.allKeyWords, state.allGroups)
    },
  },
  mutations: {
    SET_ALL_KEY_WORDS(state, allKeyWords) {
      state.allKeyWords = allKeyWords
    },
    SET_ALL_GROUPS(state, allGroups) {
      state.allGroups = allGroups
    },
    SET_ALL_SUB_GROUPS(state, allSubGroups) {
      state.allSubGroups = allSubGroups
    },
  },
  actions: {
    async findAll(ctx) {
      const { commit } = ctx
      const allKeyWords = await KeyWordsService.findAll()
      const allGroups = await GroupsService.findAll()
      // console.log(allKeyWords.filter(element => element.groupId === 2))
      // console.log('KEY_WORDS', allKeyWords)
      // console.log('GROUPS', allGroups)

      commit('SET_ALL_KEY_WORDS', allKeyWords)
      commit('SET_ALL_GROUPS', allGroups)
    },
  },
}