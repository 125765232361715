import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      top: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'lab-series',
      },
    },
    /* Auth */
    {
      path: '/auth/login',
      name: 'login',
      component: () => import('../views/auth/Login'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/auth/sign-up',
      name: 'sign-up',
      component: () => import('../views/auth/SignUp'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/auth/email',
      name: 'email',
      component: () => import('../views/auth/Email'),
      meta: {
        layout: 'blank',
      },
    },
    /* Lab */
    {
      path: '/lab/series',
      name: 'lab-series',
      component: () => import('../views/lab/series'),
    },
    {
      path: '/lab/series/:seriesId/:currentPage',
      name: 'lab-open-series',
      component: () => import('../views/lab/series/_seriesId/index'),
    },
    {
      path: '/lab/series/:seriesId/levels/:levelId',
      name: 'lab-open-level',
      component: () => import('../views/lab/series/_seriesId/level/_levelId'),
    },
    {
      path: '/lab/create-series',
      name: 'create-series',
      component: () => import('../views/lab/create-series/index'),
      meta: {
        layout: 'blank',
      },
    },
    /* Misc */
    {
      path: '/error/not-admin',
      name: 'not-admin',
      component: () => import('../views/miscellaneous/NotAdmin'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/coming-soon',
      name: 'coming-soon',
      component: () => import('../views/miscellaneous/ComingSoon'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => next())

export default router