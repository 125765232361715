import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import attitude from './attitude'
import category from './category'
import content from './content'
import question from './question'
import firebase from './firebase'
import level from './level'
import sdgIndicator from './sdgIndicator'
import series from './series'
import keywords from './keywords'
import skill from './skill'
import technique from './technique'
import user from './user'
import feedback from './feedback'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    attitude,
    category,
    content,
    question,
    firebase,
    level,
    sdgIndicator,
    series,
    keywords,
    skill,
    technique,
    user,
    feedback,
  },
  strict: process.env.DEV,
})
