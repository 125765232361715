import FeedbackAdapter from '@/adapters/feedback.adapter'
import FeedbackService from '@/services/feedback.service'

export default {
  namespaced: true,
  state: {
    feedbacks: [],
    pagination: {
      search: '',
      page: 1,
      limit: 30,
    },
  },
  getters: {
    feedbacks(state) {
      return state.feedbacks
    },
    pagination(state) {
      return state.pagination
    },
    slugs(state) {
      return state.feedbacks.map(({ slug }) => slug)
    },
  },
  mutations: {
    SET_FEEDBACKS(state, feedbacks) {
      state.feedbacks = feedbacks
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    },
    DELETE_FEEDBACK(state, id) {
      const index = state.feedbacks.findIndex(ctt => ctt.id === id)
      state.feedbacks.splice(index, 1)
    },
  },
  actions: {
    async findBySeriesId(ctx, { seriesId, pagination }) {
      const { commit } = ctx
      const { page, limit, search } = pagination
      const feedbacks = await FeedbackService.findBySeriesId(seriesId, page, limit, search)
      commit('SET_FEEDBACKS', feedbacks.map(feedback => FeedbackAdapter.convertToDisplayFormat(feedback)))
      commit('SET_PAGINATION', feedbacks.pagination)
      return feedbacks
    },
    async create(ctx, feedback) {
      const { commit } = ctx
      const saved = await FeedbackService.create(feedback)
      commit('SAVE_FEEDBACK', saved)
      return saved
    },
    async update(ctx, { id, feedback }) {
      const { commit } = ctx
      const updated = await FeedbackService.update(id, feedback)
      commit('UPDATE_FEEDBACK', updated)
      return updated
    },
    async softDelete(ctx, id) {
      const { commit } = ctx
      await FeedbackService.softDelete(id)
      commit('DELETE_FEEDBACK', id)
    },
  },
}